import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const MediaHeaderText = styled.h3`
  padding: 20px;
  font-size: 1.6em;
  color: #444;
`

const MediaHeader = ({ children }) => (
  <MediaHeaderText>{children}</MediaHeaderText>
)

MediaHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MediaHeader
