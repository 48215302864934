import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const MediaContainer = styled.div`
  margin: 50px;
  background: #eee;
  transition: transform 0.4s;
  max-width: 400px;
  &:hover {
    transform: scale(1.1);
  }
`

const Media = ({ path, children }) => (
  <MediaContainer>
    <Link
      to={path ? path : "/"}
      style={{ textDecoration: "none", color: "black" }}
    >
      <div>{children}</div>
    </Link>
  </MediaContainer>
)

Media.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Media
