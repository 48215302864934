import * as React from "react"
import PropTypes from "prop-types"

const MediaImage = ({ inactive, children }) => (
  <div>
    <div
      style={{
        display: inactive ? "initial" : "none",
        position: "absolute",
        width: "100%",
        height: "400px",
        background: "white",
        zIndex: "10",
        opacity: "0.6",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3
        style={{
          transform: "translate(150px, 200px)",
        }}
      >
        Coming Soon
      </h3>
    </div>
    {children}
  </div>
)

MediaImage.propTypes = {
  inactive: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default MediaImage
