import * as React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Media from "../components/Media/media"
import MediaImage from "../components/Media/mediaimage"
import MediaHeader from "../components/Media/mediaheader"
import MediaExcerpt from "../components/Media/mediaexcerpt"

const MediaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
`

const Img = styled(GatsbyImage)`
  height: 400px;
`

export default function IndexPage({ data }) {
  const mediaData = data.markdownRemark.frontmatter

  return (
    <Layout header='"the devil is in the detail..."'>
      <SEO
        title="Home"
        description="The devil is in the details"
        pathname="/"
      />
      <MediaContainer>
        <Media path="/costumes">
          <MediaImage inactive={mediaData.costumes_media.costumes_coming_soon}>
            <Img
              image={getImage(
                mediaData.costumes_media.costumes_image.childImageSharp
                  .gatsbyImageData
              )}
              alt={mediaData.costumes_media.costumes_header}
            />
          </MediaImage>
          <MediaHeader>{mediaData.costumes_media.costumes_header}</MediaHeader>
          <MediaExcerpt>
            {mediaData.costumes_media.costumes_excerpt}
          </MediaExcerpt>
        </Media>
        <Media>
          <MediaImage
            inactive={mediaData.denim_dog_media.denim_dog_coming_soon}
          >
            <Img
              image={getImage(
                mediaData.denim_dog_media.denim_dog_image.childImageSharp
                  .gatsbyImageData
              )}
              alt={mediaData.denim_dog_media.denim_dog_header}
            />
          </MediaImage>
          <MediaHeader>
            {mediaData.denim_dog_media.denim_dog_header}
          </MediaHeader>
          <MediaExcerpt>
            {mediaData.denim_dog_media.denim_dog_excerpt}
          </MediaExcerpt>
        </Media>
      </MediaContainer>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(frontmatter: { type: { eq: "index-page" } }) {
      frontmatter {
        costumes_media {
          costumes_header
          costumes_excerpt
          costumes_coming_soon
          costumes_image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                layout: CONSTRAINED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        denim_dog_media {
          denim_dog_coming_soon
          denim_dog_excerpt
          denim_dog_header
          denim_dog_image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                layout: CONSTRAINED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
