import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const MediaExcerptText = styled.p`
  padding: 20px;
  color: #777;
  word-wrap: break-all;
`

const MediaExcerpt = ({ children }) => (
  <MediaExcerptText>{children}</MediaExcerptText>
)

MediaExcerpt.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MediaExcerpt
